<template>
  <div class="fit row justify-center q-mb-md">
    <q-form class="col-sm-12 col-md-6">
      <q-input v-model="search" label="Busca de um Planejamento">
        <template v-slot:append>
          <q-icon name="search"></q-icon>
        </template>
      </q-input>
    </q-form>
  </div>
  <!--
  <q-list class="row justify-center q-gutter-md q-mb-md">
    <q-btn
      class="col-sm-12 col-md-3"
      no-caps
      :text-color="filterByPending ? 'grey-2' : ''"
      :outline="filterByPending ? false : true"
      color="warning"
      icon="schedule"
      align="between"
      size="xl"
      @click="pendingFilterButtonClick()"
    >
      <span>Pending: {{ pendingCounter }}</span>
    </q-btn>
    <q-btn
      class="col-sm-12 col-md-3"
      no-caps
      color="positive"
      :text-color="filterByApproved ? 'grey-2' : ''"
      :outline="filterByApproved ? false : true"
      icon="check"
      align="between"
      size="xl"
      @click="approvedFilterButtonClick()"
    >
      <span>Approved: {{ approvedCounter }}</span>
    </q-btn>
    <q-btn
      class="col-sm-12 col-md-3"
      no-caps
      :text-color="filterByDisapproved ? 'grey-2' : ''"
      :outline="filterByDisapproved ? false : true"
      color="negative"
      icon="error_outline"
      align="between"
      size="xl"
      @click="disapprovedFilterButtonClick()"
    >
      <span>Disapproved: {{ disapprovedCounter }}</span>
    </q-btn>
  </q-list>
  -->
  <q-table
    class="my-sticky-header-table"
    flat
    dense
    :columns="tableColumns"
    :rows="allPlannedDemands"
    virtual-scroll
    v-model:pagination="pagination"
    hide-pagination
    selection="multiple"
    separator="cell"
    @selection="onSelection"
    @update:selected="afterSelection"
    v-model:selected="selectedPlannedDemands"
    :rows-per-page-options="[0]"
    style="max-height: 600px"
    row-key="id"
    :filter="search"
    :loading="plannedDemands.loading"
    @row-click="rowClicked"
    :visible-columns="visibleColumns"
  >
    <!--
    <template v-slot:header="props">
      <q-tr :props="props">
        <q-th v-for="col in props.cols" :key="col.name" :props="props">
          <q-btn-dropdown
            :label="col.label"
            align="center"
            size="sm"
            dropdown-icon="search"
            menu-self="top middle"
            square
            flat
          >
            <q-input :label="'Search' + col.label">
              <template v-slot:append>
                <q-icon name="search" />
              </template>
            </q-input>
          </q-btn-dropdown>
        </q-th>
      </q-tr>
    </template>
    -->
    <template v-slot:body-cell-approval="props">
      <q-td :props="props">
        <div>
          <q-icon
            v-if="props.value == 1"
            color="positive"
            name="check"
            size="md"
          />
          <q-icon
            v-if="props.value == 0"
            color="negative"
            name="error_outline"
            size="md"
          />
          <q-icon
            v-if="props.value == null"
            name="schedule"
            color="warning"
            size="md"
          />
        </div>
        <div class="my-table-details">
          {{ props.row.details }}
        </div>
      </q-td>
    </template>
    <template v-slot:body-cell-alertas="props">
      <q-td :props="props">
        <q-chip
          v-if="props.value.length > 0"
          icon="warning_amber"
          :label="props.value.length"
          color="yellow-5"
        ></q-chip>
        <div class="my-table-details">
          {{ props.row.details }}
        </div>
      </q-td>
    </template>
    <template v-slot:top>
      <div class="q-gutter-md" v-if="selectedPlannedDemands.length > 0">
        <q-btn
          icon-right="close"
          label="Reprovar"
          color="negative"
          @click="plannedDemandMakeApproval(false)"
        ></q-btn>
        <q-btn
          icon-right="done_all"
          label="Aprovar"
          color="positive"
          @click="plannedDemandMakeApproval(true)"
        ></q-btn>
      </div>

      <div class="flex items-center" style="margin-left: auto">
        <ChipTotal :valorTotal="selectedPlannedDemandsSummation" />
        <q-select
          v-model="visibleColumns"
          multiple
          outlined
          label="Colunas Visíveis"
          dense
          options-dense
          :display-value="$q.lang.table.columns"
          emit-value
          map-options
          :options="tableColumns"
          option-value="name"
          options-cover
          style="min-width: 150px"
        />
        <q-toggle
          label="Apenas Pendentes"
          icon="schedule"
          color="warning"
          v-model="pendingFilterToggler"
          @update:model-value="requestAllPlannedDemands"
        ></q-toggle>
      </div>
    </template>
    <template v-slot:header-selection>
      <!-- Esse slot vai ficar vazio por enquanto apra desabilitar a seleção em massa, pois ainda está muito inconsistente -->
    </template>
    <template v-slot:body-selection="scope">
      <q-checkbox v-model="scope.selected" v-if="scope.row.approval == null">
      </q-checkbox>
      <!-- Esse slot vai ficar vazio por enquanto apra desabilitar a seleção em massa, pois ainda está muito inconsistente -->
    </template>
  </q-table>
  <ApprovalSubmitFormDialog
    @submitted="onApprovalSubmit"
    v-model="approvalSubmitDialogOpen"
    :approvalType="approval.approvalType"
    :selectedPlannedDemands="selectedPlannedDemands"
  />

  <MessageNotify ref="notifyRef" />
</template>

<script>
import ApprovalSubmitFormDialog from "../components/ApprovalSubmitFormDialog.vue";
import MessageNotify from "../components/MessageNotify.vue";
import {
  getAllPlannedDemands,
  getPendingPlannedDemands,
} from "../data/api/plannedDemandRequests.js";
// import { transformCamelCaseToSeparatedWords } from "../utils/string/stringManipulation.js";
import dateFormat from "dateformat";

import { ref } from "vue";

import ChipTotal from "../components/chips/ChipTotal.vue";

export default {
  name: "FutureDemandsPlanningTable",
  components: {
    ApprovalSubmitFormDialog,
    MessageNotify,
    ChipTotal,
  },
  emits: ["row-click", "submitted-approval"],
  setup() {
    const notifyRef = ref(null);
    return {
      notifyRef,
    };
  },
  data: () => ({
    pagination: 0,
    search: "",

    plannedDemands: {
      data: [],
      loading: false,
    },

    selectedPlannedDemands: [],
    recentSelectedPlannedDemands: [], // Variável auxiliar para poder fazer a filtragem da seleção

    approvalSubmitDialogOpen: false,
    approval: {
      approvalType: 0,
    },

    pendingFilterToggler: false, // v-model para o toggle de filtro para somente pendente

    visibleColumns: [
      "materialCode",
      "approval",
      "approvalDate",
      "quantity",
      "planningDate",
      "totalCost",
      "district",
      "department",
      "criticality",
      "user",
      "price",
      "shortDescription",
      "longDescription",
      "umb",
      "family",
      "currencyIso",
    ],

    tableColumns: [
      {
        name: "department",
        label: "DEPARTAMENTO",
        align: "center",
        sortable: true,
        field: "department",
      },
      {
        name: "district",
        label: "CENTRO",
        align: "center",
        sortable: true,
        field: "district",
      },
      {
        name: "materialCode",
        label: "CÓDIGO DE MATERIAL",
        align: "center",
        sortable: true,
        field: "materialCode",
      },
      {
        name: "shortDescription",
        label: "DESCRIÇÃO CURTA",
        align: "center",
        sortable: true,
        field: "shortDescription",
      },
      {
        name: "approval",
        label: "APROVAÇÃO",
        align: "center",
        sortable: true,
        field: "approval",
      },
      {
        name: "approvalDate",
        label: "DATA DE APROVAÇÃO",
        align: "center",
        sortable: true,
        field: "approvalDate",
      },
      {
        name: "quantity",
        label: "QUANTIDADE",
        align: "center",
        sortable: true,
        field: "quantity",
      },
      {
        name: "totalCost",
        label: "CUSTO TOTAL",
        align: "center",
        sortable: true,
        field: "totalCost",
      },
      {
        name: "planningDate",
        label: "DATA DE PLANEJAMENTO",
        align: "center",
        sortable: true,
        field: "planningDate",
      },
      {
        name: "user",
        label: "USUÁRIO",
        align: "center",
        sortable: true,
        field: "user",
      },
      {
        name: "criticality",
        label: "CIRITICIDADE",
        align: "center",
        sortable: true,
        field: "criticality",
      },
      {
        name: "family",
        label: "FAMÍLIA",
        align: "center",
        sortable: true,
        field: "family",
      },
      {
        name: "umb",
        label: "UMB",
        align: "center",
        sortable: true,
        field: "umb",
      },
      {
        name: "currencyIso",
        label: "ISO 4217",
        align: "center",
        sortable: true,
        field: "currencyIso",
      },
    ],
  }),
  created() {
    for (let i = 0; i < this.allDemands; i++) {
      this.alertPopup.push(false);
    }
    this.requestAllPlannedDemands();
  },
  computed: {
    /*
    columnsSetup temporáriamente desativado

    // Incrementar depois para pegar os campos genéricos do material
    columnsSetup() {
      if (
        !this.plannedDemands.loading &&
        this.plannedDemands.data.length != 0
      ) {
        let tableColumns = [];
        Object.keys(this.plannedDemands.data[0]["material"]).forEach((key) => {
          if (key != "genericField") {
            tableColumns.push({
              name: key,
              label: transformCamelCaseToSeparatedWords(key),
              align: "center",
              sortable: true,
              field: key,
            });
          }
        });
        Object.keys(this.plannedDemands.data[0]).forEach((key) => {
          if (key != "material" && key != "id") {
            tableColumns.push({
              name: key,
              label: transformCamelCaseToSeparatedWords(key),
              align: "center",
              sortable: true,
              field: key,
            });
          }
        });

        // Insere o custo total na tabela [quantidade * preço]
        tableColumns.push({
          name: "totalCost",
          label: "TOTAL COST",
          align: "center",
          sortable: true,
          field: "totalCost",
        });

        return tableColumns;
      } else {
        return [];
      }
    },
    */

    // Cria um novo objeto a partir do response do request, compatível com a table do quasar, a fim de a popular
    allPlannedDemands() {
      if (!this.plannedDemands.loading && this.plannedDemands.data) {
        let dataObjects = [];
        for (let plannedDemand of this.plannedDemands.data) {
          let newObject = {
            ...plannedDemand,
            ...plannedDemand["material"],
            department: plannedDemand["department"]["name"],
            district: plannedDemand["district"]["name"],
            approval: plannedDemand["approval"]
              ? plannedDemand["approval"]["approvalType"]
              : plannedDemand["approval"],
            approvalDate: plannedDemand["approval"]
              ? dateFormat(
                  plannedDemand["approval"]["approvalDate"],
                  "yyyy-mm-dd"
                )
              : null,
            user: plannedDemand["user"]["email"],
            planningDate: dateFormat(plannedDemand["planningDate"], "yyyy-mm"),
            totalCost: (
              parseInt(plannedDemand["quantity"]) *
              parseFloat(plannedDemand["material"]["price"])
            ).toFixed(2),
          };
          delete newObject["material"];
          delete newObject["genericField"];
          dataObjects.push(newObject);
        }
        return dataObjects;
      } else {
        return [];
      }
    },
    selectedPlannedDemandsSummation() {
      let result = 0;
      for (let i = 0; i < this.selectedPlannedDemands.length; i++) {
        const element = this.selectedPlannedDemands[i];
        result += parseFloat(element.totalCost);
      }
      return result.toFixed(2);
    },
  },
  methods: {
    rowClicked(evt, row) {
      this.$emit("row-click", row);
    },

    // Faz a requisição para pegar os planejamentos
    requestAllPlannedDemands() {
      this.plannedDemands.loading = true;
      if (this.pendingFilterToggler) {
        let request = getPendingPlannedDemands();
        request.then((response) => {
          if (response.status == 200) {
            this.plannedDemands.data = [...response.data];
          } else if (response.status == 400) {
            this.$refs.notifyRef.showNotify(response.data, "warning");
          }
        });
      } else {
        let request = getAllPlannedDemands();
        request.then((response) => {
          if (response.status == 200) {
            this.plannedDemands.data = [...response.data];
          } else if (response.status == 400) {
            this.$refs.notifyRef.showNotify(response.data, "warning");
          }
        });
      }
      this.plannedDemands.loading = false;
    },

    // Ao clicar no botão de aprovar ou reprovar aciona esse método que atualizará o valor de approvalType para o props do ApprovalSubmitDialog
    plannedDemandMakeApproval(approval) {
      approval
        ? (this.approval.approvalType = 1)
        : (this.approval.approvalType = 0);
      this.approvalSubmitDialogOpen = true;
    },

    // eslint-disable-next-line no-unused-vars
    onSelection({ rows, added, evt }) {
      if (added) this.recentSelectedPlannedDemands = rows;
    },
    filterSelectionAndNotify(recentSelectedArray, allSelectedArray) {
      //console.log(allSelectedArray);
      //console.log(this.selectedPlannedDemands);
      let recentSelectedArrayCopy = [...recentSelectedArray];
      let notifications = {
        sameKeyFound: false,
        approvedPlanningFound: false,
      };
      while (recentSelectedArrayCopy.length > 0) {
        let recentSelectedCopy = recentSelectedArrayCopy.pop();
        for (let recentSelectedCopyinArray of recentSelectedArrayCopy) {
          if (
            recentSelectedCopy["materialCode"] ==
              recentSelectedCopyinArray["materialCode"] &&
            recentSelectedCopy["district"] ==
              recentSelectedCopyinArray["district"] &&
            recentSelectedCopy["department"] ==
              recentSelectedCopyinArray["department"] &&
            recentSelectedCopy["planningDate"] ==
              recentSelectedCopyinArray["planningDate"]
          ) {
            //console.log(recentSelectedCopyinArray);
            if (notifications["sameKeyFound"] == false)
              notifications["sameKeyFound"] = true;

            allSelectedArray.splice(
              allSelectedArray.indexOf(recentSelectedCopyinArray),
              1
            );

            this.$refs.notifyRef.showNotify(
              "Não é possível selectionar mais de um planejamento com a mesma chave (código de material, departamento, centro e data de planejamento)",
              "warning"
            );
          }
        }
      }
      for (let recentSelected of recentSelectedArray) {
        // Checa se o planejamento feito já possui aprovação
        if (recentSelected["approval"] != null) {
          //console.log(recentSelected);
          allSelectedArray.splice(allSelectedArray.indexOf(recentSelected), 1);
          this.$refs.notifyRef.showNotify(
            "Não é possível selecionar um planejamento que já possui uma aprovação",
            "warning"
          );
        } else {
          for (let selectedRow of this.selectedPlannedDemands) {
            // Checa se selecionou algum planejamento com a mesma chave que algum já selecionado
            if (
              recentSelected["materialCode"] == selectedRow["materialCode"] &&
              recentSelected["district"] == selectedRow["district"] &&
              recentSelected["department"] == selectedRow["department"] &&
              recentSelected["planningDate"] == selectedRow["planningDate"]
            ) {
              //console.log(recentSelected);
              if (notifications["sameKeyFound"] == false)
                notifications["sameKeyFound"] = true;

              allSelectedArray.splice(
                allSelectedArray.indexOf(recentSelected),
                1
              );

              this.$refs.notifyRef.showNotify(
                "Não é possível selectionar mais de um planejamento com a mesma chave (código de material, departamento, centro e data de planejamento)",
                "warning"
              );
            }
          }
        }
      }
      return allSelectedArray;
    },
    afterSelection(newSelected) {
      let selectedArrayFiltered;
      selectedArrayFiltered = this.filterSelectionAndNotify(
        this.recentSelectedPlannedDemands,
        newSelected
      );

      this.selectedPlannedDemands = [...selectedArrayFiltered];
      this.recentSelectedPlannedDemands = [];
    },

    // Quando o ApprovalSubmitFormDialog é submetido, aciona esse método que reatualizara a tabela
    onApprovalSubmit(approvalSucceded) {
      if (approvalSucceded) {
        this.selectedPlannedDemands = [];
        this.requestAllPlannedDemands();
        this.$emit("submitted-approval", approvalSucceded);
      }
    },

    /*
    arrayMove(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing
    },
    */
  },
};
</script>
