import axios from "axios";
import { getToken } from "../../utils/jwt/tokenUtils.js";
import router from "../../router/index.js";
import { removeToken } from "../../utils/jwt/tokenUtils.js";
//import router from "vue-router";

let baseURL =
  process.env.NODE_ENV == "production"
    ? "https://demetra-api.mroativa.com.br/api/"
    : "http://localhost:5000/api/";

// baseURL = process.env.API_HOST ?? "http://localhost:5000/api/";

// const baseURL = "https://demetra-api.herokuapp.com/api/";

// Cria a instância do Axios
const service = axios.create({
  baseURL: baseURL,
  //timeout: 10000, // Timeout
});

// request
service.interceptors.request.use(
  (config) => {

    // console.log("url: " + baseURL); // Teste
    // console.log("api_host: " + process.env.API_HOST); // Teste
    // console.log("node_env: " + process.env.NODE_ENV);

    const token = getToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + getToken(); // insere o token JWT
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
    console.log(error); // para debug
    // Faz algo com o erro da request
  }
);

service.interceptors.response.use(
  // eslint-disable-next-line no-unused-vars
  (response) => {
    return response;
  },
  (error) => {
    // Caso algum request dê 401, por causa do token, a aplicação será redirecionada a tela de Login
    if (error.response.status == 401) {
      removeToken();
      router.push({ name: "Login" });
    } else {
      return error.response;
    }
  }
);

export default service;
