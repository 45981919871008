<template>
  <div class="text-subtitle1">{{ titleText }}</div>
  <apexchart
    ref="chartRef"
    :options="options"
    :height="height"
    :series="series"
  ></apexchart>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

import { getConsumeHistory } from "../data/api/consumeRequests.js";
import {
  getCurrentPlannedDemands,
  getExpectedPlannedDemands,
} from "../data/api/plannedDemandRequests.js";
import {
  makeFullYearMonthArrayFromMultipleYearMonthArrays,
  makeArrayOfYearMonthFromToday,
} from "../utils/datetimeUtils.js";
//import { makeArrayofObjectKeyFromArrayOfObject } from "../utils/objectUtils.js";
import dateFormat from "dateformat";

import { ref } from "vue";

export default {
  name: "DemandHistoryLineGraph",
  components: {
    apexchart: VueApexCharts,
  },
  setup() {
    const chartRef = ref(null);
    return {
      chartRef,
    };
  },
  props: {
    materialCode: {
      type: [Number, String],
      required: true,
      default: "",
    },
    district: {
      type: String,
      required: false,
      default: "",
    },
    planningData: {
      type: Object,
      required: false,
      default: null,
    },
    height: {
      type: String,
      required: false,
      default: "auto",
    },
  },
  data: () => ({
    options: {
      chart: {
        id: "history-graph",
      },
      markers: {
        size: 5,
      },
      stroke: {
        width: 3,
        curve: "straight",
      },
      //labels: makeArrayOfYearMonthFromToday(24, 12),
      xaxis: {
        type: "category",
        categories: makeArrayOfYearMonthFromToday(24, 12),
        tickAmount: "dataPoints",
      },
    },
    series: [
      {
        type: "line",
        name: "Consumo",
        data: [],
      },
      {
        type: "line",
        name: "Vigente",
        data: [],
      },
      {
        type: "line",
        name: "Previsto",
        data: [],
      },
      /* {
        type: "line",
        name: "Expected",
        data: [],
      }, */
    ],

    consumeHistory: {
      data: [],
      loading: false,
    },
    currentPlannedDemand: {
      data: [],
      loading: false,
    },
    expectedPlannedDemands: {
      data: [],
      loading: false,
    },

    concatDates: [],
  }),
  created() {
    if (!!this.district && !!this.materialCode) {
      this.cleanGraphData();
      this.getConsumeHistoryData();
      this.getCurrentPlannedDemandData();
      this.getExpectedPlannedDemand();
      // this.changeTitleText();
    }
  },
  computed: {
    titleText() {
      if (this.materialCode == "") return "";
      return `Material ${this.materialCode}`;
    },
    nullDataArray() {
      return new Array(this.options.xaxis.categories.length).fill(null);
    },
  },
  methods: {
    // mapDateValueDataToSeriesArray(dateValueArray, dateArray) {},
    cleanGraphData() {
      this.series[0].data = [];
      this.series[1].data = [];
    },
    updateLabels() {
      if (this.currentPlannedDemand.loading || this.consumeHistory.loading)
        return [];
      return makeFullYearMonthArrayFromMultipleYearMonthArrays([
        ...this.concatDates,
      ]);
    },

    /* updateXaxisCategories() {
      let xaxisCategories = [];
      if (!this.consumeHistory.loading) {
        for (let consume of this.consumeHistory.data) {
          let date = new Date(consume["consumeDate"]);
          xaxisCategories.push(dateFormat(date, "yyyy-mm"));
        }
      }

      this.options = {
        ...this.options,
        ...{
          xaxis: {
            categories: xaxisCategories,
          },
        },
      };
    }, */

    // Essa função faz o labels desaparecer. Precisa-se fazer algo para atualizar o título do gráfico sem prejudicar o labels
    // this.options.title.text = "novo texto" não funciona
    /* changeTitleText() {
      this.options = {
        ...this.options,
        title: {
          text: "Material " + this.materialCode,
        },
      };
    }, */
    updateConsumeData() {
      let graphData = [];
      if (!this.consumeHistory.loading) {
        for (let consume of this.consumeHistory.data) {
          consume["quantity"]
            ? graphData.push(parseInt(consume["quantity"]))
            : graphData.push(0);
        }
      }
      this.series[0].data = [...graphData];
      graphData = [];
      if (!this.currentPlannedDemand.loading) {
        for (let plannedDemand of this.currentPlannedDemand.data) {
          // console.log(plannedDemand);
          plannedDemand["quantity"]
            ? graphData.push(parseInt(plannedDemand["quantity"]))
            : graphData.push(0);
        }
      }
      this.series[1].data = [...graphData];
      // console.log(this.series[1]);
    },
    convertRequestDataToPairedGraphData(data, xName, yName) {
      let graphData = [];
      for (let item of data) {
        graphData.push({
          x: dateFormat(item[xName], "yyyy-mm"),
          y: item[yName],
        });
      }
      return graphData;
    },
    getConsumeHistoryData() {
      this.consumeHistory.loading = true;
      if (this.materialCode && this.district) {
        let request = getConsumeHistory(this.materialCode, this.district, 24);
        request.then((response) => {
          //console.log(response.data);
          if (response.data) {
            // this.consumeHistory.data = [...response.data];
            // this.series[0].data = this.makeConsumeHistoryDataSeries();
            this.series[0].data = this.makeRequestedDataIntoSeriesData(
              response.data,
              "consumeDate"
            );
            /* this.concatDates = this.concatDates.concat(
              makeArrayofObjectKeyFromArrayOfObject(
                this.consumeHistory.data,
                "consumeDate"
              )
            ); */
            // console.log(this.concatDates);
            /* this.series[0].data = this.convertRequestDataToPairedGraphData(
              response.data,
              "consumeDate",
              "quantity"
            ); */
            // console.log(this.series[0].data);
            // this.updateConsumeData();
            // this.updateXaxisCategories();
          }
        });
      }
      this.consumeHistory.loading = false;
      //console.log(this.getXaxisCategories);
      //console.log(this.getGraphData);
    },
    // dataArray => array de dados
    makeConsumeHistoryDataSeries() {
      if (this.consumeHistory.loading) return this.nullDataArray;
      let seriesArray = [...this.nullDataArray];
      for (let data of this.consumeHistory.data) {
        let formattedDate = dateFormat(data["consumeDate"], "yyyy-mm");
        let index = this.options.xaxis.categories.indexOf(formattedDate);
        if (index != -1) seriesArray[index] = data["quantity"];
      }
      return seriesArray;
    },
    /*
    requestedDataArray => vetor de dados do request
    requestedDataDateKey => string do nome da chave que possui a data. Exemplo: "planningDate" ou "ConsumeDate")
   */
    makeRequestedDataIntoSeriesData(requestedDataArray, requestedDataDateKey) {
      if (this.consumeHistory.loading) return this.nullDataArray;
      let seriesArray = [...this.nullDataArray];
      for (let data of requestedDataArray) {
        let formattedDate = dateFormat(data[requestedDataDateKey], "yyyy-mm");
        let index = this.options.xaxis.categories.indexOf(formattedDate);
        if (index != -1) seriesArray[index] = data["quantity"];
      }
      return seriesArray;
    },
    getCurrentPlannedDemandData() {
      this.currentPlannedDemand.loading = true;
      if (this.materialCode && this.district) {
        let request = getCurrentPlannedDemands(
          this.materialCode,
          this.district
        );
        request.then((response) => {
          //console.log(response.data);
          if (response.data) {
            // this.currentPlannedDemand.data = [...response.data];
            this.series[1].data = this.makeRequestedDataIntoSeriesData(
              response.data,
              "planningDate"
            );
            /* this.concatDates = this.concatDates.concat(
              makeArrayofObjectKeyFromArrayOfObject(
                this.currentPlannedDemand.data,
                "planningDate"
              )
            ); */
            // console.log(this.concatDates);
            /* this.series[1].data = this.convertRequestDataToPairedGraphData(
              response.data,
              "planningDate",
              "quantity"
            ); */
            // console.log(this.series[1].data);
            // this.updateConsumeData();
            // this.updateXaxisCategories();
          }
        });
      }
      this.currentPlannedDemand.loading = false;
    },

    getExpectedPlannedDemand() {
      this.expectedPlannedDemands.loading = true;
      if (this.materialCode && this.district) {
        let request = getExpectedPlannedDemands(
          this.materialCode,
          this.district,
          24
        );
        request.then((response) => {
          // console.log("Expected = ", response.data);
          if (response.data) {
            // this.currentPlannedDemand.data = [...response.data];
            this.series[2].data = this.makeRequestedDataIntoSeriesData(
              response.data,
              "planningDate"
            );
          }
        });
      }
      this.expectedPlannedDemands.loading = false;
    },
  },
  watch: {
    /*
    graphXaxisCategories: function () {
      this.changeXaxisCategories();
    },
    */
    materialCode: function () {
      // this.concatDates = [];
      // this.cleanGraphData();
      this.getConsumeHistoryData();
      this.getCurrentPlannedDemandData();
      this.getExpectedPlannedDemand();
      //this.changeTitleText();
      //this.updateConsumeData();
      //this.updateXaxisCategories();
    },
    district: function () {
      // this.concatDates = [];
      // this.cleanGraphData();
      this.getConsumeHistoryData();
      this.getCurrentPlannedDemandData();
      this.getExpectedPlannedDemand();
      //this.changeTitleText();
    },
  },
};
</script>
