<template>
  <q-page padding class="flex">
    <div class="row items-center justify-between" style="width: 100%">
      <q-select
        class="q-mb-md"
        filled
        dense
        style="width: 200px"
        v-model="userDistricts.selected"
        :options="userDistricts.options"
        stack-label
        use-input
        label="Centro"
        :display-value="userDistricts.selected"
        @filter="filterDistricts"
      >
      </q-select>
      <q-btn
        label="planejar"
        icon-right="assignment"
        color="primary"
        @click="dialogs.planningFormDialog = true"
      />
    </div>
    <div class="fit row wrap justify-between items-start content-start">
      <ToolbarCardComponent class="col-12 q-mb-md" title="Demand History">
        <DemandHistoryLineGraph
          :height="'200px'"
          :materialCode="materialCode"
          :district="userDistricts.selected"
        />
      </ToolbarCardComponent>
      <ToolbarCardComponent class="col-12 q-mb-md" title="Materials Table">
        <MaterialsTable style="height: 500px" flat @get-row="getRowOnClick" />
      </ToolbarCardComponent>
      <PlanningUploadFormDialog v-model="dialogs.planningFormDialog" />
      <!-- <ToolbarCardComponent
        class="col-md-6 col-xs-12"
        title="Future Demands Requests"
      >
        <FutureDemandPlanningForm
          :materialId="materialCode"
          :district="userDistricts.selected"
        />
      </ToolbarCardComponent> -->
    </div>
  </q-page>
</template>

<script>
//import DemandHistoryLineChart from "../components/DemandHistoryLineChart.vue";
import ToolbarCardComponent from "../components/ToolbarCardComponent.vue";
import DemandHistoryLineGraph from "../components/DemandHistoryLineGraph.vue";
import MaterialsTable from "../components/MaterialsTable.vue";
import PlanningUploadFormDialog from "../components/planningPageComponents/PlanningUploadFormDialog.vue";
// import FutureDemandPlanningForm from "../components/FutureDemandPlanningForm.vue";
import { getUserDepartmentDistricts } from "../data/api/districtsRequests.js";

export default {
  name: "PlanningPage",
  components: {
    ToolbarCardComponent,
    DemandHistoryLineGraph,
    MaterialsTable,
    PlanningUploadFormDialog,
    // FutureDemandPlanningForm,
  },
  data: () => ({
    consumeHistory: () => [],
    materialCode: "",
    grupo: "",

    userDistricts: {
      loading: false,
      data: [],
      selected: "",
      options: [],
    },

    dialogs: {
      planningFormDialog: false,
    },
  }),
  mounted() {
    this.getUserDepartmentDistricts();
  },
  methods: {
    getRowOnClick(row) {
      this.materialCode = row["materialCode"];
    },
    // Request para pegar todos os districts do usuário
    getUserDepartmentDistricts() {
      this.userDistricts.loading = true;
      let request = getUserDepartmentDistricts();
      request.then((response) => {
        //console.log(response.data);
        for (let data of response.data) {
          this.userDistricts.data.push(data["name"]);
          this.userDistricts.options.push(data["name"]);
        }
        this.userDistricts.loading = false;
      });
    },
    // Filtro para o dropdown de district do usuário
    filterDistricts(val, update) {
      update(() => {
        const needle = val.toLowerCase();
        this.userDistricts.options = this.userDistricts.data.filter(
          (v) => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.variables.scss";

.card-toolbar-background-mro {
  background-color: $purpleMRO1;
  color: white;
}
</style>
