<template>
  <q-layout view="hHh Lpr lFf">
    <q-page-container>
      <q-page padding class="bg-img row">
        <!-- <q-card class="fixed-center" :style="{ width: '500px' }"> -->
        <q-card class="fixed-center col-lg-3 col-xs-11">
          <q-card-section
            class="flex content-start items-center justify-between"
          >
            <span class="text-h4">Login</span>
            <q-img width="75px" src="../assets/01.MRO_logotipo_principal.png" />
          </q-card-section>
          <q-separator />
          <q-card-section>
            <q-form @submit="login">
              <q-input
                class="q-mb-md"
                v-model="loginForm.email"
                type="email"
                label="E-mail"
                :rules="[(val) => val.length > 0 || 'Email obrigatório']"
              >
                <template v-slot:append>
                  <q-icon name="alternate_email" />
                </template>
              </q-input>
              <q-input
                class="q-mb-md"
                v-model="loginForm.password"
                label="Senha"
                type="password"
                :rules="[(val) => val.length > 0 || 'Senha obrigatória']"
              >
                <template v-slot:append>
                  <q-icon name="password" />
                </template>
              </q-input>
              <q-btn
                class="q-mb-md"
                label="login"
                type="submit"
                color="positive"
                icon-right="login"
                :loading="loadingRequest"
                :disable="loadingRequest"
              >
              </q-btn>
            </q-form>
            <div class="fit row wrap justify-end items-start content-start">
              <q-btn
                @click="dialogs.passwordResetDialog = true"
                color="primary"
                flat
                size="sm"
                dense
                label="Esqueci minha senha"
              ></q-btn>
            </div>
          </q-card-section>
        </q-card>
      </q-page>
    </q-page-container>
  </q-layout>

  <PasswordResetDialog v-model="dialogs.passwordResetDialog" />

  <MessageNotify ref="notifyRef" />
</template>

<script>
import MessageNotify from "../components/MessageNotify.vue";
import PasswordResetDialog from "../components/UserSettings/PasswordResetDialog.vue";

import { login } from "../data/api/loginRequests.js";
import { setToken } from "../utils/jwt/tokenUtils.js";

import { ref } from "vue";
//import { redirectToLoginPage } from "../utils/requestUtils/requestErrorHandlers.js";

export default {
  name: "LoginPage",
  components: {
    MessageNotify,
    PasswordResetDialog,
  },
  setup() {
    const notifyRef = ref(null);
    return {
      notifyRef,
    };
  },
  data: () => ({
    loginForm: {
      email: "",
      password: "",
    },
    loadingRequest: false,
    dialogs: {
      passwordResetDialog: false,
    },
  }),

  methods: {
    login() {
      this.loadingRequest = true;
      const router = this.$router;
      let response = login(this.loginForm);
      response.then((response) => {
        if (response.status == 200 && response.data["accessToken"]) {
          setToken(response.data["accessToken"]);
          this.$refs.notifyRef.showNotify(response.data["message"], "positive");
          if (response.data["isAdmin"]) router.push({ name: "Home" });
          else if (response.data["isManager"])
            router.push({ name: "SupervisorPage" });
          else router.push({ name: "Planning" });
          // router.push({ name: "Home" });
        } else {
          if (response.data["title"]) {
            this.$refs.notifyRef.showNotify(response.data["title"], "warning");
          } else {
            //console.log(response);
            this.$refs.notifyRef.showNotify(
              response.data["message"],
              "warning"
            );
          }
        }
      });
      this.loadingRequest = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-img {
  background-image: url("../assets/mro_login_background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
