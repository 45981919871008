<template>
  <q-page padding class="flex flex-center">
    <q-img
      width="500px"
      alt="mro logo"
      src="../assets/01.MRO_logotipo-horizontal_principal.png"
    />
  </q-page>
</template>

<script>
export default {
  name: "HomePage",
};
</script>
