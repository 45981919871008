<template>
  <q-card>
    <q-card-section>
      <slot name="header"></slot>
      <slot></slot>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  name: "ToolbarCardComponent",
  props: {
    title: { type: String, required: false, default: "" },
    withOptions: Boolean,
    content: { type: String, required: false, default: "" },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.variables.scss";

.card-toolbar-background-mro {
  background-color: $purpleMRO1;
  color: white;
}
</style>
