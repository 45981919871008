<template>
  <q-layout view="hHh Lpr lFf">
    <q-page-container>
      <q-page class="full-width row wrap justify-center content-start" padding>
        <Header />
        <q-card class="col-lg-5" flat>
          <q-card-section>
            <div class="text-h4">Redefinição de Senha</div>
          </q-card-section>
          <q-separator />
          <q-card-section>
            <q-form @submit="resetPassword">
              <!-- <q-input name="token" v-model="token" class="hidden"></q-input>
              <q-input label="" v-model="email" class="hidden"></q-input> -->
              <q-input
                label="Nova Senha"
                name="password"
                v-model="form.password"
                lazy-rules
                type="password"
                :rules="[
                  (val) => val.length > 0 || 'Senha obrigatória',
                  (val) =>
                    val.length >= 8 || 'Senha deve conter mais que 8 dígitos',
                ]"
              ></q-input>
              <q-input
                label="Repita a Senha"
                name="passwordConfirmation"
                v-model="form.passwordConfirmation"
                lazy-rules
                type="password"
                :rules="[
                  (val) => val.length > 0 || 'Senha obrigatória',
                  (val) =>
                    val.length >= 8 || 'Senha deve conter mais que 8 dígitos',
                  (val) =>
                    val == form.password ||
                    'Nova senha e confirmação devem ser identicas',
                ]"
              ></q-input>
              <q-btn type="submit" color="primary" label="Redefinir"></q-btn>
            </q-form>
          </q-card-section>
        </q-card>
        <MessageNotify ref="notifyRef" />
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import { useRoute } from "vue-router";
import { ref } from "vue";
import Header from "../../components/Menu/Header.vue";
import MessageNotify from "../../components/MessageNotify.vue";

import { resetPassword } from "../../data/api/userRequests.js";

// eslint-disable-next-line no-unused-vars
const emptyForm = {
  password: "",
  passwordConfirmation: "",
};

export default {
  name: "PasswordResetPage",
  components: {
    Header,
    MessageNotify,
  },
  setup() {
    const route = useRoute();
    const notifyRef = ref(null);
    return {
      route,
      notifyRef,
    };
  },
  computed: {
    token() {
      return this.route.query.token ? this.route.query.token : "";
    },
    email() {
      return this.route.query.email ? this.route.query.email : "";
    },
  },
  data: () => ({
    form: {
      password: "",
      passwordConfirmation: "",
    },
  }),
  methods: {
    resetPassword() {
      this.form["token"] = this.token;
      this.form["email"] = this.email;

      let request = resetPassword(this.form);
      request.then((response) => {
        if (response.status == 200) {
          this.$refs.notifyRef.showNotify(response.data, "positive");
          this.$router.push({ name: "Login" });
        }
        if (response.status == 400) {
          this.$refs.notifyRef.showNotify(response.data, "warning");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/quasar.variables.scss";

.header-background-mro {
  background-color: $blueMRO2;
}
</style>
