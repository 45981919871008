<template>
  <q-dialog ref="dialogRef">
    <q-card style="width: 1000px">
      <q-card-section class="">
        <div class="text-h4 q-mb-md">Aprovação</div>
        <div class="caption">
          Você está
          <span :class="approvalTypeColorClass">{{ approvalTypeMessage }}</span>
          {{ selectedPlannedDemandsCount }} planejamentos com um custo total de
          R$ {{ selectedPlannedDemandsTotal }}
        </div>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-section>
        <q-form class="q-gutter-md" @submit="onApprovalSubmit">
          <q-input
            label="Descrição da aprovação"
            type="text"
            v-model="approvalForm['approval']['description']"
          >
            <template v-slot:append>
              <q-icon name="subject"></q-icon>
            </template>
          </q-input>
          <q-btn
            type="submit"
            :color="approvalType == 1 ? 'positive' : 'negative'"
            label="Submeter aprovação"
            icon-right="check"
          />
        </q-form>
      </q-card-section>
    </q-card>
    <MessageNotify ref="notifyRef" />
  </q-dialog>
</template>

<script>
import { ref } from "vue";
import { approvePlannedDemand } from "../data/api/approvalRequest.js";
import MessageNotify from "../components/MessageNotify.vue";

const approvalDefaultForm = {
  approvalType: null,
  description: "",
};

export default {
  name: "ApprovalSubmitFormDialog",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    MessageNotify,
  },
  setup() {
    const dialogRef = ref(null);
    const notifyRef = ref(null);
    return {
      dialogRef,
      notifyRef,
    };
  },
  data: () => ({
    approvalForm: {
      plannedDemandsGuidList: [],
      approval: {
        approvalType: null,
        description: "",
      },
    },
  }),
  props: {
    selectedPlannedDemands: {
      type: Array,
      required: true,
      default: null,
    },
    approvalType: {
      type: Number,
      required: true,
      default: null,
    },
  },
  computed: {
    selectedPlannedDemandsGuidList() {
      let guidList = [];
      for (let selected of this.selectedPlannedDemands) {
        guidList.push(selected["id"]);
      }
      return guidList;
    },
    selectedPlannedDemandsTotal() {
      let result = 0;
      for (let selected of this.selectedPlannedDemands) {
        result += parseFloat(selected.totalCost);
      }
      return result.toFixed(2);
    },
    selectedPlannedDemandsCount() {
      return this.selectedPlannedDemands.length;
    },
    approvalTypeMessage() {
      return this.approvalType == 1 ? "APROVANDO" : "REPROVANDO";
    },
    approvalTypeColorClass() {
      return this.approvalType == 1 ? "text-positive" : "text-negative";
    },
  },
  methods: {
    onApprovalSubmit() {
      this.approvalForm["approval"]["approvalType"] = this.approvalType;
      this.approvalForm["plannedDemandsGuidList"] =
        this.selectedPlannedDemandsGuidList;
      let request = approvePlannedDemand(this.approvalForm);
      request.then((response) => {
        if (response.status == 200) {
          this.$refs.notifyRef.showNotify("Approval succeded", "positive");
          this.$emit("submitted", true);
          this.$refs.dialogRef.hide();
        } else if (response.status == 400) {
          this.$emit("submitted", false);
          this.$refs.notifyRef.showNotify(response.data, "warning");
        } else {
          this.$emit("submitted", false);
          this.$refs.notifyRef.showNotify(response.data, "negative");
        }
      });
    },

    // Limpa o form de aprovação
    cleanDescriptionForm() {
      this.approval = [...approvalDefaultForm];
    },
  },
};
</script>
