<template>
  <q-dialog @hide="resetForm">
    <q-card style="width: 25%">
      <q-card-section>
        <div class="text-h5 q-mb-md">Alterar Senha</div>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <q-form @submit="formSubmit">
          <q-input
            label="Senha atual"
            v-model="passwordChangeForm.oldPassword"
            lazy-rules
            type="password"
            :rules="[
              (val) => val.length > 0 || 'Senha obrigatória',
              (val) =>
                val.length >= 8 || 'Senha deve conter mais que 8 dígitos',
            ]"
          ></q-input>
          <q-input
            label="Nova senha"
            v-model="passwordChangeForm.newPassword"
            lazy-rules
            type="password"
            :rules="[
              (val) => val.length > 0 || 'Senha obrigatória',
              (val) =>
                val.length >= 8 || 'Senha deve conter mais que 8 dígitos',
            ]"
          ></q-input>
          <q-input
            label="Confirme a nova senha"
            v-model="passwordChangeForm.newPasswordConfirmation"
            lazy-rules
            type="password"
            :rules="[
              (val) => val.length > 0 || 'Senha obrigatória',
              (val) =>
                val.length >= 8 || 'Senha deve conter mais que 8 dígitos',
              (val) =>
                val == passwordChangeForm.newPassword ||
                'Nova senha e confirmação devem ser identicas',
            ]"
          ></q-input>
          <q-btn
            type="submit"
            icon-right="check"
            class="q-mt-md"
            color="positive"
            label="Confirmar"
          ></q-btn>
        </q-form>
      </q-card-section>
    </q-card>
    <MessageNotify ref="notifyRef" />
  </q-dialog>
</template>

<script>
import { changePassword } from "../../data/api/userRequests.js";
import MessageNotify from "../../components/MessageNotify.vue";
import { ref } from "vue";

const passwordChangeForm = {
  oldPassword: "",
  newPassword: "",
  newPasswordConfirmation: "",
};

export default {
  name: "PasswordChangeDialog",
  components: {
    MessageNotify,
  },
  setup() {
    const notifyRef = ref(null);
    return {
      notifyRef,
    };
  },
  data: () => ({
    passwordChangeForm: {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
    },
  }),
  methods: {
    resetForm() {
      this.passwordChangeForm = { ...passwordChangeForm };
    },
    formSubmit() {
      /* Deletando o newPasswordConfirmation, pois não é necessário para a API.
      Feito isso para manter o formulário do jeito que está para caso de erro da API. */
      let form = { ...this.passwordChangeForm };
      delete form.newPasswordConfirmation;

      let request = changePassword(form);
      request.then((response) => {
        if (response.status == 200) {
          this.$refs.notifyRef.showNotify(response.data, "positive");
        }
        if (response.status == 400) {
          this.$refs.notifyRef.showNotify(response.data, "warning");
        }
      });
    },
  },
};
</script>
