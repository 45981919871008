import service from "./baseRequest.js";

// Recebe um objeto FormData() para poder processar e enviar o arquivo
export function getUserFromJwt() {
  return service.get("users/fromjwt");
}

export function changePassword(passwordChangeForm) {
  return service.put("users/changePassword", passwordChangeForm);
}

export function sendPasswordResetEmail(emailForm) {
  return service.post("users/sendPasswordResetEmail", emailForm);
}

export function resetPassword(passwordResetForm) {
  return service.put("users/resetPassword", passwordResetForm);
}
