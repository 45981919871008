import { removeDuplicates } from "./arrayUtils";
import dateFormat from "dateformat";

export function makeFullYearMonthArrayFromMultipleYearMonthArrays(
  yearMonthArray
) {
  if (yearMonthArray.length == 0 || yearMonthArray == null) return [];
  yearMonthArray = removeDuplicates(yearMonthArray);
  yearMonthArray.sort();

  for (let index in yearMonthArray) {
    yearMonthArray[index] = dateFormat(yearMonthArray[index], "yyyy-mm");
  }

  return makeFullYearMonthArrayFromRangeValues(
    yearMonthArray[0],
    yearMonthArray[yearMonthArray.length - 1]
  );
}

function makeFullYearMonthArrayFromRangeValues(minDate, maxDate) {
  if (maxDate < minDate) return;
  let splitYearMonthDate = minDate.split("-");
  let year = splitYearMonthDate[0];
  let month = splitYearMonthDate[1];
  let auxYearMonthString = year + "-" + month;

  let fullArray = [];

  while (auxYearMonthString != maxDate) {
    fullArray.push(auxYearMonthString);

    month = (parseInt(month) + 1).toString();
    if (month / 10 < 1) month = "0" + month;

    if (month == "13") {
      year = (parseInt(year) + 1).toString();
      month = "01";
    }

    auxYearMonthString = year + "-" + month;
  }
  fullArray.push(maxDate);
  return fullArray;
}

export function addMonthsToYearMonthDate(date, months) {
  if (months > 0) {
    return addMonths(date, months);
  } else {
    return subtractMonths(date, Math.abs(months));
  }
}

// Para formato yyyy-mm
function addMonths(date, months) {
  let splittedDate = date.split("-");
  let monthFromDate = parseInt(splittedDate[1]);
  let yearFromDate = parseInt(splittedDate[0]);
  if (months < 0) return date;
  for (let index = 0; index < months; index++) {
    monthFromDate += 1;
    if (monthFromDate == 13) {
      monthFromDate = 1;
      yearFromDate += 1;
    }
  }
  if (monthFromDate / 10 < 1) monthFromDate = "0" + monthFromDate.toString();
  else monthFromDate = monthFromDate.toString();
  return yearFromDate.toString() + "-" + monthFromDate;
}

// Para formato yyyy-mm
function subtractMonths(date, months) {
  let splittedDate = date.split("-");
  let monthFromDate = parseInt(splittedDate[1]);
  let yearFromDate = parseInt(splittedDate[0]);
  if (months < 0) return date;
  for (let index = 0; index < months; index++) {
    monthFromDate -= 1;
    if (monthFromDate == 0) {
      monthFromDate = 12;
      yearFromDate -= 1;
    }
  }
  if (monthFromDate / 10 < 1) monthFromDate = "0" + monthFromDate.toString();
  else monthFromDate = monthFromDate.toString();
  return yearFromDate.toString() + "-" + monthFromDate;
}

export function makeArrayOfYearMonthFromToday(monthsAgo, monthsToCome) {
  // eslint-disable-next-line no-unused-vars
  let nowTime = new Date();
  nowTime = dateFormat(nowTime, "yyyy-mm");
  return makeFullYearMonthArrayFromRangeValues(
    addMonthsToYearMonthDate(nowTime, -monthsAgo),
    addMonthsToYearMonthDate(nowTime, monthsToCome)
  );
}
