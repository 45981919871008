import service from "./baseRequest.js";

// Recebe um objeto FormData() para poder processar e enviar o arquivo
export function postDemandPlanningExcel(fileUploadForm) {
  return service.post("PlannedDemand", fileUploadForm, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export function getPendingPlannedDemands() {
  return service.get("PlannedDemand/pending");
}

export function getApprovedPlannedDemands() {
  return service.get("PlannedDemand/approved");
}

export function getReprovedPlannedDemands() {
  return service.get("PlannedDemand/reproved");
}

export function getAllPlannedDemands() {
  return service.get("PlannedDemand");
}

export function getUsersPlannedDemands() {
  return service.get("PlannedDemand/user");
}

export function getCurrentPlannedDemands(materialCode, districtName) {
  return service.get(
    `PlannedDemand/currentapproved/${materialCode}/${districtName}`
  );
}

export function getUserDepartmentCurrentPlannedDemands() {
  return service.get(`PlannedDemand/currentapproved/`);
}

export function getExpectedPlannedDemands(
  materialCode,
  districtName,
  monthsAgo
) {
  return service.get(
    `PlannedDemand/expectedplanneddemand/${materialCode}/${districtName}/${monthsAgo}`
  );
}
