<template>
  <Header @open-drawer="openDrawer" />

  <!-- Remover esse código, após terminar os testes com o componente "Header" -->
  <!-- <q-header reveal class="header-background-mro basic">
    <q-toolbar>
      <q-btn
        flat
        dense
        round
        @click="leftDrawerOpen = !leftDrawerOpen"
        aria-label="Menu"
        icon="menu"
        v-if="hasToken"
      />
      <router-link :to="hasToken ? 'Home' : ''">
        <q-img
          clickable
          :style="{ marginLeft: '1rem' }"
          width="100px"
          alt="mro logo"
          src="../assets/01.MRO_logotipo_principal.png"
        />
      </router-link>
      <q-toolbar-title>
        <div class="title-h6">DEMETRA</div>
      </q-toolbar-title>
      <q-btn v-if="hasToken" icon="logout" color="negative" @click="logout"
        >Logout</q-btn
      >
    </q-toolbar>
  </q-header> -->

  <q-drawer
    v-if="hasToken"
    v-model="leftDrawerOpen"
    bordered
    overlay
    class="bg-grey-2"
  >
    <q-list>
      <q-item-label header>Informações do Usuário</q-item-label>
      <q-item>
        <q-item-section>
          <q-item-label
            ><span class="text-overline">{{
              userFunctionText
            }}</span></q-item-label
          >
          <q-item-label
            ><span class="text-weight-bold">Nome: </span
            ><span class="text-caption" v-if="!user.loading">{{
              userNameText
            }}</span></q-item-label
          >
          <q-item-label v-if="!isAdmin"
            ><span class="text-weight-bold">Departamento: </span
            ><span class="text-caption" v-if="!user.loading">{{
              userDepartmentName
            }}</span></q-item-label
          >
          <q-item-label v-if="userManagerName && !isAdmin"
            ><span class="text-weight-bold">Gerente: </span
            ><span class="text-caption" v-if="!user.loading">{{
              userManagerName
            }}</span></q-item-label
          >
        </q-item-section>
      </q-item>

      <q-separator spaced />
      <div v-if="!isManager && !isAdmin">
        <q-item-label header>Planejamento</q-item-label>
        <q-item clickable tag="a" to="/planning">
          <q-item-section avatar>
            <q-icon name="event_note" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Planejamento</q-item-label>
            <q-item-label caption>Área de Planejamento</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable tag="a" to="/planninglog">
          <q-item-section avatar>
            <q-icon name="history" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Log de Planejamento</q-item-label>
            <q-item-label caption
              >Histórico e acompanhamento dos planejamentos</q-item-label
            >
          </q-item-section>
        </q-item>
      </div>
      <div v-if="isManager && !isAdmin">
        <q-item-label header>Gerência</q-item-label>
        <q-item clickable tag="a" to="/supervisor">
          <q-item-section avatar>
            <q-icon name="engineering" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Gerência</q-item-label>
            <q-item-label caption>Aprovação de Planejamentos</q-item-label>
          </q-item-section>
        </q-item>
      </div>
      <div v-if="canUpdateConsume && !isAdmin">
        <q-item-label header>Configuração do Cliente</q-item-label>
        <q-item clickable tag="a" to="ConsumeUpdate">
          <q-item-section avatar>
            <q-icon name="fact_check" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Consumo</q-item-label>
            <q-item-label caption>Atualização do consumo</q-item-label>
          </q-item-section>
        </q-item>
      </div>
      <q-separator spaced />

      <!-- Implementar após completar a parte administrativa da aplicação -->

      <q-item-label header>Configuração do Usuário</q-item-label>
      <!-- <q-item clickable tag="a" to="/clients">
        <q-item-section avatar>
          <q-icon name="business" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Clients</q-item-label>
          <q-item-label caption>Clients Settings</q-item-label>
        </q-item-section>
      </q-item> -->
      <q-item clickable tag="a" @click="dialogs.passwordChange = true">
        <q-item-section avatar>
          <q-icon name="manage_accounts" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Alterar Senha</q-item-label>
          <q-item-label caption>Alteração da senha do usuário</q-item-label>
        </q-item-section>
      </q-item>
      <!-- <q-item clickable tag="a" to="">
        <q-item-section avatar>
          <q-icon name="text_snippet"></q-icon>
        </q-item-section>
        <q-item-section>
          <q-item-label>Log</q-item-label>
          <q-item-label caption
            >Hitory of all approvals and plannings</q-item-label
          >
        </q-item-section>
      </q-item> -->
    </q-list>
  </q-drawer>
  <PasswordChangeDialog v-model="dialogs.passwordChange" />
</template>

<script>
import { ref } from "vue";
import { getToken } from "../utils/jwt/tokenUtils.js";
import { getUserFromJwt } from "../data/api/userRequests.js";

import PasswordChangeDialog from "../components/UserSettings/PasswordChangeDialog.vue";
import Header from "../components/Menu/Header.vue";

export default {
  name: "Menu",
  components: {
    PasswordChangeDialog,
    // eslint-disable-next-line vue/no-unused-components
    Header,
  },
  setup() {
    return {
      leftDrawerOpen: ref(false),
    };
  },
  data: () => ({
    user: {
      data: null,
      loading: false,
    },
    dialogs: {
      passwordChange: false,
    },
  }),
  computed: {
    hasToken() {
      return getToken() ? true : false;
    },

    userFunctionText() {
      if (this.user.data) {
        if (this.isAdmin) {
          return "Administrador";
        } else if (this.isManager) {
          return "Gerente";
        } else return "Planejador";
      }
      return "";
    },
    userNameText() {
      if (this.user.data) {
        return this.user.data["name"];
      }
      return "";
    },
    userDepartmentName() {
      if (this.user.data) {
        return this.user.data["department"]["name"];
      }
      return "";
    },
    userManagerName() {
      if (this.user.data && this.user.data["manager"]) {
        return this.user.data["manager"]["name"];
      }
      return "";
    },
    isManager() {
      if (this.user.data) {
        return this.user.data["isManager"] ? true : false;
      }
      return false;
    },
    isAdmin() {
      if (this.user.data) {
        return this.user.data["isAdmin"] ? true : false;
      }
      return false;
    },
    canUpdateConsume() {
      if (this.user.data) {
        return this.user.data["canUpdateConsume"] ? true : false;
      }
      return false;
    },
  },
  mounted() {
    this.requestUser();
  },
  methods: {
    requestUser() {
      this.user.loading = true;
      let request = getUserFromJwt();
      request.then((response) => {
        if (response.status == 200) this.user.data = response.data;
        this.user.loading = false;
      });
    },
    openDrawer(openerFlag) {
      console.log(openerFlag);
      this.leftDrawerOpen = openerFlag;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.variables.scss";

.header-background-mro {
  background-color: $blueMRO2;
}
</style>
