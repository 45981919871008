import service from "./baseRequest.js";

export function getConsumeHistory(materialCode, districtName, monthsAgo) {
  return service.get(`consumes/${materialCode}/${districtName}/${monthsAgo}`);
}

export function postConsume(form) {
  return service.post(`consumes/`, form);
}

export function postConsumeExcel(form) {
  return service.post(`consumes/excel`, form, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
