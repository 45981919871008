<template>
  <q-card class="bg-transparent no-shadow no-border">
    <q-card-section class="q-pa-none">
      <div class="row q-col-gutter-sm">
        <div
          v-for="(item, index) in progressItems"
          :key="index"
          class="col-md-4 col-sm-12 col-xs-12"
        >
          <q-item :style="`background-color: ${item.color}`" class="q-pa-none">
            <q-item-section class="q-pa-md q-ml-none text-white">
              <q-item-label class="text-white text-h6 text-weight-bolder"
                >{{ item.percentage }}%</q-item-label
              >
              <q-item-label
                >{{ item.title }} - {{ item.value }}/{{
                  item.total
                }}</q-item-label
              >
            </q-item-section>
            <q-item-section side class="q-mr-md text-white">
              <q-icon :name="item.icon" color="white" size="44px"></q-icon>
            </q-item-section>
          </q-item>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import { getApprovalProgress } from "../../data/api/approvalRequest";

export default {
  name: "CardProgresso",
  mounted() {
    this.loadApprovalProgress();
  },
  data: () => ({
    approvalProgress: {
      data: {},
      loading: false,
    },
  }),
  computed: {
    progressItems() {
      if (!this.approvalProgress.loading && this.approvalProgress.data != []) {
        return [
          {
            title: "Aprovado",
            icon: "check",
            value: this.approvalProgress.data.approved,
            percentage: this.approvalProgress.data.approvedPercentage,
            total: this.approvalProgress.data.total,
            color: "#3a9688",
          },
          {
            title: "Reprovado",
            icon: "error_outline",
            value: this.approvalProgress.data.reproved,
            percentage: this.approvalProgress.data.reprovedPercentage,
            total: this.approvalProgress.data.total,
            color: "#f37169",
          },
          {
            title: "Pendente",
            icon: "schedule",
            value: this.approvalProgress.data.pending,
            percentage: this.approvalProgress.data.pendingPercentage,
            total: this.approvalProgress.data.total,
            color: "#f88c2b",
          },
        ];
      } else {
        return [];
      }
    },
  },
  methods: {
    loadApprovalProgress() {
      this.approvalProgress.loading = true;
      let request = getApprovalProgress();
      request.then((response) => {
        if (response.status == 200) {
          this.approvalProgress.data = response.data;
        }
      });
      this.approvalProgress.loading = false;
    },
  },
};
</script>
