<template>
  <q-header reveal class="header-background-mro basic">
    <q-toolbar>
      <q-btn
        flat
        dense
        round
        @click="openDrawer"
        aria-label="Menu"
        icon="menu"
        v-if="hasToken"
      />
      <router-link :to="hasToken ? 'Home' : ''">
        <q-img
          clickable
          :style="{ marginLeft: '1rem' }"
          width="100px"
          alt="mro logo"
          src="../../assets/01.MRO_logotipo_principal.png"
        />
      </router-link>
      <q-toolbar-title>
        <div class="title-h6">DEMETRA</div>
      </q-toolbar-title>
      <q-btn v-if="hasToken" icon="logout" color="negative" @click="logout"
        >Logout</q-btn
      >
    </q-toolbar>
  </q-header>
</template>

<script>
import { removeToken, getToken } from "../../utils/jwt/tokenUtils.js";

export default {
  name: "Header",
  data: () => ({
    leftDrawerOpen: false,
  }),
  computed: {
    hasToken() {
      return getToken() ? true : false;
    },
  },
  methods: {
    openDrawer() {
      this.leftDrawerOpen = !this.leftDrawerOpen;
      this.$emit("open-drawer", this.leftDrawerOpen);
    },

    logout() {
      removeToken();
      this.$router.push({ name: "Login" });
    },
  },
};
</script>
