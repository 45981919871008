<template>
  <q-dialog>
    <q-card style="width: 500px" bordered>
      <q-card-section>
        <!-- Utilizar isso para outro MVP, para o planejador realizar algum planejamento específico -->
        <!--
  <div v-if="!!materialId">
    <div v-if="materialId != ''" class="text-h6 q-mb-lg">
      Material:
      <span :style="{ color: '#3a78de' }">
        {{ materialId }}
      </span>
      District:
      <span :style="{ color: '#3a78de' }">
        {{ district ? district : "None" }}
      </span>
    </div>
    
    <q-form @submit="makePlanning(month, materialId)" v-if="materialId != ''">
      <div
        class="
          fit
          row
          wrap
          justify-center
          items-start
          content-start
          q-gutter-xs
        "
      >
        <q-input
          class="col-5"
          v-for="(month, index) in nextMonthsArray"
          :key="materialId + month"
          :name="materialId + '_' + month"
          filled
          :label="'Demand for ' + month"
          type="number"
          dense
          v-model="demandPlanningFields[index].value"
          lazy-rules
          :rules="[
            (val) => val >= 0 || 'Please, insert a number bigger than 0.',
          ]"
        />
      </div>
      <div class="fit row items-center content-start justify-center">
        <q-btn class="" color="secondary">Submit Demands</q-btn>
      </div>
    </q-form>
    
  </div>
  <div v-else>
    <q-chip
      square
      color="orange"
      label="Please, click on a material in the Material Table"
    ></q-chip>
  </div>
  -->

        <!-- Produção
    <a
    style="text-decoration: none"
    download="Demand_Upload_Template.xlsx"
    href="/Demand_Upload.xlsx"
  >
    <q-btn
      color="secondary"
      class="q-mb-md"
      label="Template Download"
      size="sm"
      padding="sm"
      icon="file_download"
    />
  </a>
  -->
        <!-- Desenvolvimento -->
        <a
          style="text-decoration: none"
          download="Template_Upload_Planejamento.xlsx"
          href="/files/Template_Upload_Planejamento.xlsx"
        >
          <q-btn
            color="secondary"
            class="q-mb-md"
            label="Download do Template"
            size="sm"
            padding="sm"
            icon="file_download"
          />
        </a>
        <q-form @submit="demandPlanningFileFormSubmit">
          <q-file
            class="q-mb-md"
            clearable
            bottom-slots
            v-model="demandPlanningFileForm.file"
            label="Upload do Excel de Planejamento"
            counter
            accept=".xlsx"
          >
            <template v-slot:append>
              <q-icon name="attach_file" />
            </template>
            <template v-slot:hint> Tamanho do arquivo </template>
          </q-file>
          <q-btn
            color="primary"
            type="submit"
            label="Submeter Planejamento"
            icon-right="file_upload"
            :loading="demandPlanningFileForm.loading"
          >
          </q-btn>
        </q-form>
        <MessageNotify ref="notifyRef" />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { postDemandPlanningExcel } from "../../data/api/plannedDemandRequests.js";
import MessageNotify from "../../components/MessageNotify.vue";

import { ref } from "vue";

export default {
  name: "PlanningUploadFormDialog",
  components: {
    MessageNotify,
  },
  setup() {
    const notifyRef = ref(null);
    return {
      notifyRef,
    };
  },
  props: {
    materialId: {
      type: [String, Number],
      required: false,
      default: "",
    },
    district: {
      type: String,
      required: false,
      default: "",
    },
  },
  data: () => ({
    demandPlanningFileForm: {
      loading: false,
      file: null,
    },
    demandPlanningForm: {},
  }),
  computed: {},
  methods: {
    demandPlanningSubmit(index, month) {
      console.log(
        index,
        month,
        parseInt(this.demandInputVmodelObjects[index].value)
      );
    },
    demandPlanningFileFormSubmit() {
      this.demandPlanningFileForm.loading = true;
      if (this.demandPlanningFileForm.file) {
        let formData = new FormData();
        formData.append("file", this.demandPlanningFileForm.file);
        let request = postDemandPlanningExcel(formData);
        request.then((response) => {
          if (response.status == 200)
            this.$refs.notifyRef.showNotify("Planned successfully", "positive");
          else if (response.status >= 400 && response.status < 500)
            this.$refs.notifyRef.showNotify(response.data, "warning");
          else if (response.status >= 500)
            this.$refs.notifyRef.showNotify(response.data, "negative");
        });
      }
      this.demandPlanningFileForm.loading = false;
    },
  },
  watch: {
    materialId: function () {
      this.resetVmodels();
    },
  },
};
</script>

<style lang="scss">
// @import "../styles/quasar.variables.scss";

// .card-toolbar-background-mro {
//   background-color: $purpleMRO1;
//   color: white;
// }
// .title-chip-colors {
//   background-color: $blueMRO2;
//   color: white;
// }
//
</style>
