<template>
  <q-page
    class="full-width row wrap justify-center content-start items-start"
    padding
  >
    <q-card flat style="width: 75%">
      <q-card-section>
        <div class="flex justify-between items-center q-mb-md">
          <div class="text-h5 text-indigo-10">Atualização dos Consumos</div>
          <a
            style="text-decoration: none"
            download="Consume_Upload.xlsx"
            href="files/Consumes_Upload_BRA.xlsx"
          >
            <q-btn
              icon-right="download"
              color="secondary"
              label="Download do Template"
            >
            </q-btn
          ></a>
        </div>
        <q-form @submit="multipleConsumesFormSubmit">
          <div class="q-mb-md">
            <q-file
              type="file"
              label="Excel de consumos"
              v-model="multipleForm.file"
              :rules="[(val) => !!val || 'Arquivo obrigatório']"
            >
              <template v-slot:append>
                <q-icon name="attach_file"></q-icon>
              </template>
            </q-file>
          </div>
          <q-btn color="primary" type="submit" label="Enviar"></q-btn>
        </q-form>
      </q-card-section>
      <q-separator></q-separator>
      <!-- <q-tabs
        v-model="tab"
        dense
        class="bg-purple-6 text-white"
        active-color="purple-3"
        indicator-color="purple-3"
        align="justify"
        narrow-indicator
      >
        <q-tab name="multiplo" label="Múltiplo" />
        <q-tab name="individual" label="Individual" />
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="individual">
          <div class="text-h5">Entrada singular</div>
          <q-form @submit="singleConsumeFormSubmit">
            <div class="q-mb-md">
              <q-input
                name="materialCode"
                type="text"
                label="Código do material"
                v-model="singularForm.materialCode"
                :rules="[(val) => !!val || 'Código do material é obrigatório']"
              ></q-input>
              <q-input
                name="districtName"
                type="text"
                label="Código do centro"
                v-model="singularForm.district.name"
                :rules="[(val) => !!val || 'Código do centro é obrigatório']"
              ></q-input>
              <q-input
                name="districtName"
                type="text"
                label="Código do departamento"
                v-model="singularForm.department.name"
                :rules="[
                  (val) => !!val || 'Código do departamento é obrigatório',
                ]"
              ></q-input>
              <q-input
                name="quantity"
                type="number"
                label="Quantidade"
                v-model="singularForm.quantity"
                :rules="[
                  (val) =>
                    val >= 0 ||
                    'Quantidade é obrigatória e deve ser um número positivo.',
                  (val) => !!val || 'Quantidade é obrigatório',
                ]"
              ></q-input>
              <q-input
                name="consumeDate"
                type="text"
                label="Ano-mês (Ex: 2022-01)"
                v-model="singularForm.consumeDate"
                :rules="[
                  (val) => !!val || 'Data é obrigatória',
                  (val) =>
                    consumeDateValidation(val) || 'Formato de data inválido.',
                ]"
              ></q-input>
            </div>
            <q-btn color="primary" type="submit" label="Enviar"></q-btn>
          </q-form>
        </q-tab-panel>

        <q-tab-panel name="multiplo">
          <div class="flex justify-between items-center q-mb-md">
            <div class="text-h5">Entrada múltipla</div>
            <a
              style="text-decoration: none"
              download="Consume_Upload.xlsx"
              href="files/Consumes_Upload_BRA.xlsx"
            >
              <q-btn
                icon-right="download"
                color="secondary"
                label="Download do Template"
              >
              </q-btn
            ></a>
          </div>
          <q-form @submit="multipleConsumesFormSubmit">
            <div class="q-mb-md">
              <q-file
                type="file"
                label="Excel de consumos"
                v-model="multipleForm.file"
                :rules="[(val) => !!val || 'Arquivo obrigatório']"
              >
                <template v-slot:append>
                  <q-icon name="attach_file"></q-icon>
                </template>
              </q-file>
            </div>
            <q-btn color="primary" type="submit" label="Enviar"></q-btn>
          </q-form>
        </q-tab-panel>
      </q-tab-panels> -->
    </q-card>
    <MessageNotify ref="notifyRef" />
  </q-page>
</template>

<script>
import { postConsumeExcel } from "../data/api/consumeRequests.js";
import MessageNotify from "../components/MessageNotify.vue";
import { ref } from "vue";

export default {
  name: "ConsumeUpdatePage",
  setup() {
    let notifyRef = ref(null);
    return {
      notifyRef,
    };
  },
  components: {
    MessageNotify,
  },
  data: () => ({
    tab: "multiplo",

    singularForm: {
      materialCode: "",
      district: {
        name: "",
      },
      department: {
        name: "",
      },
      consumeDate: "",
      quantity: null,
    },

    multipleForm: {
      file: null,
    },
  }),
  computed: {},
  methods: {
    singleConsumeFormSubmit() {},
    multipleConsumesFormSubmit() {
      let formData = new FormData();
      formData.append("file", this.multipleForm.file);
      let request = postConsumeExcel(formData);
      request.then((response) => {
        if (response.status == 200) {
          this.$refs.notifyRef.showNotify(response.data, "positive");
          this.multipleForm.file = null;
        }
        if (response.status == 400) {
          this.$refs.notifyRef.showNotify(response.data, "warning");
          this.multipleForm.file = null;
        }
      });
    },
    consumeDateValidation(formattedDate) {
      let regex = /\d{4}-((0[1-9])|(1[0-2]))/g;
      if (!regex.test(formattedDate)) return false;
      else if (formattedDate.split("-")[0] == "0000") return false;
      return true;
    },
  },
};
</script>
