<template>
  <q-page
    class="full-width row wrap justify-center content-start items-start"
    padding
  >
    <q-card flat class="col-10">
      <q-card-section>
        <span class="text-h3 title-color">Aprovação</span>
      </q-card-section>
      <q-separator />

      <!-- Progress -->
      <q-card-section>
        <CardProgresso ref="progressCardRef" />
      </q-card-section>

      <q-card-section>
        <!--
        <FutureDemandsPlanningTable @open-dialog="dialogOpener" />
        -->
        <FutureDemandsPlanningTable
          @submitted-approval="updateProgressCard"
          @row-click="dialogOpener"
        />
      </q-card-section>
    </q-card>
    <SupervisorDialogCard
      v-model="supervisorDialog.opened"
      :dialogData="supervisorDialog.data"
    >
    </SupervisorDialogCard>
  </q-page>
</template>

<script>
import FutureDemandsPlanningTable from "../components/FutureDemandsPlanningTable.vue";
import SupervisorDialogCard from "../components/SupervisorDialogCard.vue";
import CardProgresso from "../components/cards/CardProgresso.vue";
// import { getApprovalProgress } from "../data/api/approvalRequest";

import { ref } from "vue";

export default {
  name: "SupervisorPage",
  components: {
    CardProgresso,
    FutureDemandsPlanningTable,
    SupervisorDialogCard,
  },
  setup() {
    const progressCardRef = ref(null);
    return {
      progressCardRef,
    };
  },
  data: () => ({
    dialog: false,
    supervisorDialog: {
      opened: false,
      data: null,
    },
    dialogData: null,
    plannings: null,
  }),
  created() {},
  computed: {
    pendingCounter() {
      return FutureDemandsPlanningTable.pendingCounter;
    },
  },

  methods: {
    dialogOpener(row) {
      this.supervisorDialog.opened = true;
      this.supervisorDialog.data = row;
    },
    /* 
    Atualiza o card de progresso via ref quando alguma aprovação é feita na tabela
    */
    // eslint-disable-next-line no-unused-vars
    updateProgressCard(approvalSucceded) {
      this.$refs.progressCardRef.loadApprovalProgress();
    },
  },
};
</script>

<style lang="scss">
@import "../styles/quasar.variables.scss";

.title-color {
  color: $blueMRO1;
}
.subtitle-color {
  color: $greenMRO1;
}
</style>
