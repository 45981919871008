import service from "./baseRequest.js";

export function approvePlannedDemand(approvalForm) {
  //console.log(approvalForm);
  return service.post("approvals", approvalForm);
}

export function getApprovalProgress() {
  return service.get("approvals/getapprovalprogress");
}
