<template>
  <q-layout view="hHh Lpr lFf">
    <Menu />
    <q-page-container>
      <router-view></router-view>
    </q-page-container>
  </q-layout>
</template>

<script>
import Menu from "../components/Menu.vue";

export default {
  name: "BaseView",
  components: {
    Menu,
  },
};
</script>
