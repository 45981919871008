<template>
  <div class="q-pa-md q-gutter-md">
    <div>
      <q-chip color="primary" text-color="white">
        Total selecionado:<span> </span>
        <h6 style="margin-left: 1rem">
          R$ {{ valorTotal.toString().replace(".", ",") }}
        </h6>
      </q-chip>
    </div>
  </div>
</template>

<!-- <template>
  <q-card class="bg-transparent no-shadow no-border">
    <q-card-section class="q-pa-none">
      <div class="row q-col-gutter-sm ">
          <q-item :style="`background-color: ${item.color1}`" class="q-pa-none col-md-12">
            <q-item-section class=" q-pa-md q-ml-none  text-white">
              <q-item-label class="text-white text-h6 text-weight-bolder">$ {{ valorTotal }}</q-item-label>
              <q-item-label>{{ item.title }}</q-item-label>
            </q-item-section>
          </q-item>
      </div>
    </q-card-section>
  </q-card>
</template> !-->

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ChipTotal",
  props: {
    valorTotal: {
      required: false,
      default: "left",
    },
  },
  //   computed: {
  //     item: function () {
  //       return {
  //             title: "Total aprovado",
  //             icon: "fas fa-dollar-sign",
  //             value: "$ 20k",
  //             color1: "#546bfa",
  //             color2: "#3e51b5"
  //           };
  //     }
  //   }
});
</script>
