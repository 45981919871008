import {
  createRouter,
  createWebHashHistory,
  // createWebHistory,
} from "vue-router";
import HomePage from "../views/HomePage.vue";
import PlanningPage from "../views/PlanningPage.vue";
import PlanningLogPage from "../views/PlanningLogPage.vue";
import SupervisorPage from "../views/SupervisorPage.vue";
/* import ClientsTablePage from "../views/ClientsTablePage.vue";
import ClientPage from "../views/ClientPage.vue";
import ClientFormPage from "../views/ClientFormPage.vue"; */
import LoginPage from "../views/LoginPage.vue";
import BaseView from "../views/BaseView.vue";
import ConsumeUpdatePage from "../views/ConsumeUpdatePage.vue";
import PasswordResetPage from "../views/PasswordReset/PasswordResetPage.vue";

import { getToken } from "../utils/jwt/tokenUtils.js";

const routes = [
  {
    path: "/",
    name: "Base",
    component: BaseView,
    children: [
      {
        path: "/home",
        name: "Home",
        component: HomePage,
      },
      {
        path: "/planning",
        name: "Planning",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: PlanningPage,
      },
      {
        path: "/planninglog",
        name: "PlanningLog",
        component: PlanningLogPage,
      },
      {
        path: "/consumeupdate",
        name: "ConsumeUpdate",
        component: ConsumeUpdatePage,
      },
      {
        path: "/supervisor",
        name: "SupervisorPage",
        component: SupervisorPage,
      },
      /* {
        path: "/clients",
        name: "ClientsPage",
        component: ClientsTablePage,
      },
      {
        path: "/clients/:id",
        name: "ClientPage",
        component: ClientPage,
      },
      {
        path: "/client-form",
        name: "ClientFormPage",
        component: ClientFormPage,
      }, */
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/passwordReset",
    name: "PasswordReset",
    component: PasswordResetPage,
  },
];

const router = createRouter({
  // mode: "history",
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["Login", "PasswordReset"];
  const authRequired = !publicPages.includes(to.name);
  const token = getToken();
  //console.log(token);
  if (to.name == "Base") {
    next({ name: "Home" });
  } else if (to.name == "Login" && token) {
    next({ name: to.name });
  } else if (authRequired && !token) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
