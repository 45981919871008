<template>
  <q-dialog @hide="resetForm">
    <div class="row window-width">
      <q-card class="col-lg-12 col-xs-12">
        <q-card-section>
          <div class="text-h5 q-mb-md">Resetar Senha</div>
        </q-card-section>
        <q-separator />
        <q-card-section>
          <q-form @submit="formSubmit">
            <q-input
              label="Email"
              v-model="passwordResetForm.email"
              lazy-rules
              :rules="[
                (val) => val.length > 0 || 'Email Obrigatório',
                (val) => validateEmail || 'Email inválido',
              ]"
            >
              <template v-slot:append>
                <q-icon name="alternate_email" /> </template
            ></q-input>
            <q-btn
              type="submit"
              icon-right="check"
              class="q-mt-md"
              color="positive"
              label="Confirmar"
            ></q-btn>
          </q-form>
        </q-card-section>
      </q-card>
    </div>
    <MessageNotify ref="notifyRef" />
  </q-dialog>
</template>

<script>
import { sendPasswordResetEmail } from "../../data/api/userRequests.js";
import MessageNotify from "../MessageNotify.vue";
import { ref } from "vue";
import { validateEmail } from "../../utils/validations.js";

const passwordResetForm = {
  email: "",
};

export default {
  name: "PasswordResetDialog",
  components: {
    MessageNotify,
  },
  setup() {
    const notifyRef = ref(null);
    return {
      notifyRef,
    };
  },
  data: () => ({
    passwordResetForm: {
      email: "",
    },
  }),
  computed: {
    validateEmail() {
      return validateEmail(this.passwordResetForm.email);
    },
  },
  methods: {
    resetForm() {
      this.passwordResetForm = { ...passwordResetForm };
    },
    formSubmit() {
      /* Deletando o newPasswordConfirmation, pois não é necessário para a API.
      Feito isso para manter o formulário do jeito que está para caso de erro da API. */
      let form = { ...this.passwordResetForm };
      delete form.newPasswordConfirmation;

      let request = sendPasswordResetEmail(form);
      request.then((response) => {
        if (response.status == 200) {
          this.$refs.notifyRef.showNotify(response.data, "positive");
        }
        if (response.status == 400) {
          this.$refs.notifyRef.showNotify(response.data, "warning");
        }
      });
    },
  },
};
</script>
