<template>
  <!-- <div class="q-pa-md"> -->
  <q-table
    flat
    dense
    :rows="formattedTableData"
    :columns="tableColumns"
    style="max-height: 400px"
    row-key="id"
    hide-pagination
    virtual-scroll
    v-model:pagination="pagination"
    :rows-per-page-options="[0]"
  >
    <template v-slot:body-cell-approval="props">
      <q-td :props="props">
        <div>
          <q-icon
            v-if="props.value == 1"
            color="positive"
            name="check"
            size="md"
          />
          <q-icon
            v-if="props.value == 0"
            color="negative"
            name="error_outline"
            size="md"
          />
          <q-icon
            v-if="props.value == null"
            name="schedule"
            color="warning"
            size="md"
          />
        </div>
        <div class="my-table-details">
          {{ props.row.details }}
        </div>
      </q-td>
    </template>
  </q-table>
  <!-- </div> -->
  <!-- <div>
      Retorno: {{ items }}
  </div> -->
</template>

<script>
import { defineComponent } from "vue";
import dateFormat from "dateformat";

export default defineComponent({
  name: "PlanningLogTable",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    userPlannedDemands: {
      data: [],
      loading: false,
    },
    pagination: 0,
    tableColumns: [
      {
        name: "department",
        label: "DEPARTAMENTO",
        align: "center",
        sortable: true,
        field: "department",
      },
      {
        name: "district",
        label: "CENTRO",
        align: "center",
        sortable: true,
        field: "district",
      },
      {
        name: "materialCode",
        label: "CÓDIGO DE MATERIAL",
        align: "center",
        sortable: true,
        field: "materialCode",
      },
      {
        name: "shortDescription",
        label: "DESCRIÇÃO CURTA",
        align: "center",
        sortable: true,
        field: "shortDescription",
      },
      {
        name: "approval",
        label: "APROVAÇÃO",
        align: "center",
        sortable: true,
        field: "approval",
      },
      {
        name: "approvalDate",
        label: "DATA DE APROVAÇÃO",
        align: "center",
        sortable: true,
        field: "approvalDate",
      },
      {
        name: "quantity",
        label: "QUANTIDADE",
        align: "center",
        sortable: true,
        field: "quantity",
      },
      {
        name: "totalCost",
        label: "CUSTO TOTAL",
        align: "center",
        sortable: true,
        field: "totalCost",
      },
      {
        name: "planningDate",
        label: "DATA DE PLANEJAMENTO",
        align: "center",
        sortable: true,
        field: "planningDate",
      },
      {
        name: "user",
        label: "USUÁRIO",
        align: "center",
        sortable: true,
        field: "user",
      },
      {
        name: "criticality",
        label: "CIRITICIDADE",
        align: "center",
        sortable: true,
        field: "criticality",
      },
      {
        name: "family",
        label: "FAMÍLIA",
        align: "center",
        sortable: true,
        field: "family",
      },
      {
        name: "umb",
        label: "UMB",
        align: "center",
        sortable: true,
        field: "umb",
      },
      {
        name: "currencyIso",
        label: "ISO 4217",
        align: "center",
        sortable: true,
        field: "currencyIso",
      },
    ],
  }),
  computed: {
    formattedTableData() {
      return this.tableData != null
        ? this.tableData.map((plannedDemand) =>
            this.usersPlannedDemandsMapper(plannedDemand)
          )
        : null;
    },
  },
  setup() {},
  //   created(){
  //       this.loadUsersPlannedDemands()
  //   },
  mounted() {},
  methods: {
    usersPlannedDemandsMapper(plannedDemand) {
      // console.log(plannedDemand);
      let result = {
        id: plannedDemand.id,
        department: plannedDemand["department"]
          ? plannedDemand["department"]["name"]
          : plannedDemand["department"],

        district: plannedDemand["district"]
          ? plannedDemand["district"]["name"]
          : plannedDemand["district"],

        materialCode: plannedDemand["material"]
          ? plannedDemand["material"]["materialCode"]
          : plannedDemand["material"],

        shortDescription: plannedDemand["material"]
          ? plannedDemand["material"]["shortDescription"]
          : plannedDemand["material"],

        criticality: plannedDemand["material"]
          ? plannedDemand["material"]["criticality"]
          : plannedDemand["material"],

        family: plannedDemand["material"]
          ? plannedDemand["material"]["family"]
          : plannedDemand["material"],

        currencyIso: plannedDemand["material"]
          ? plannedDemand["material"]["currencyIso"]
          : plannedDemand["material"],

        umb: plannedDemand["material"]
          ? plannedDemand["material"]["umb"]
          : plannedDemand["material"],

        approval: plannedDemand["approval"]
          ? plannedDemand["approval"]["approvalType"]
          : plannedDemand["approval"],

        approvalDate: plannedDemand["approval"]
          ? dateFormat(plannedDemand["approval"]["approvalDate"], "yyyy-mm-dd")
          : plannedDemand["approval"],

        quantity: plannedDemand["quantity"],

        user: plannedDemand["user"]["email"],
        planningDate: dateFormat(plannedDemand["planningDate"], "yyyy-mm"),
        totalCost: (
          parseInt(plannedDemand["quantity"]) *
          parseFloat(plannedDemand["material"]["price"])
        ).toFixed(2),
      };
      return result;
    },
  },
});
</script>
