<template>
  <q-page
    class="full-width row wrap justify-center content-start items-start"
    padding
  >
    <!-- Tabela dos planejamentos do usuário -->
    <q-card flat class="col-10 q-mb-xs">
      <q-card-section>
        <span class="text-h3 text-indigo-10 title-color"
          >Log de Planejamento</span
        >
      </q-card-section>
      <q-separator></q-separator>
      <q-card-section>
        <div class="text-h5 text-indigo-10 q-mb-md">
          Acompanhamento dos Planejamentos
        </div>
        <!-- <PlanningLogTable class="col-12 q-mb-md" /> -->
        <PlannedDemandTable
          :tableData="userPlannedDemands.data"
          class="col-12 q-mb-md"
        />
      </q-card-section>
      <q-card-section>
        <div class="text-h5 text-indigo-10 q-mb-md">
          Acompanhamento dos Planejamentos Vigentes
        </div>
        <PlannedDemandTable
          :tableData="currentPlannedDemands.data"
          class="col-12 q-mb-md"
        />
      </q-card-section>
      <q-separator></q-separator>
    </q-card>

    <!-- Tabela de planejamentos correntes -->
    <!-- <q-card flat class="col-10">
      <q-separator></q-separator>
      
    </q-card> -->
  </q-page>
</template>

<script>
import {
  getUserDepartmentCurrentPlannedDemands,
  getUsersPlannedDemands,
} from "../data/api/plannedDemandRequests.js";
// import PlanningLogTable from "../components/planningLogPageComponents/PlanningLogTable.vue";
import PlannedDemandTable from "../components/tables/PlannedDemandsTable.vue";

export default {
  name: "PlanningLogPage",
  components: {
    // PlanningLogTable,
    PlannedDemandTable,
  },
  data: () => ({
    userPlannedDemands: {
      data: [],
      loading: false,
    },
    currentPlannedDemands: {
      data: [],
      loading: false,
    },
  }),
  mounted() {
    this.loadUsersPlannedDemands();
    this.loadCurrentPlannedDemands();
  },
  methods: {
    loadCurrentPlannedDemands() {
      if (
        !this.currentPlannedDemands.loading &&
        this.currentPlannedDemands.data
      ) {
        this.currentPlannedDemands.loading = true;
        let request = getUserDepartmentCurrentPlannedDemands();
        request.then((response) => {
          if (response.data) {
            // console.log(response.data);
            this.currentPlannedDemands.data = [...response.data];
          }
        });
      } else {
        return;
      }
      this.currentPlannedDemands.loading = false;
    },
    loadUsersPlannedDemands() {
      if (!this.userPlannedDemands.loading && this.userPlannedDemands.data) {
        this.userPlannedDemands.loading = true;
        let request = getUsersPlannedDemands();
        request.then((response) => {
          if (response.data) {
            //console.log(response.data);
            this.userPlannedDemands.data = [...response.data];
          }
        });
      } else {
        return;
      }
      this.userPlannedDemands.loading = false;
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.variables.scss";

.card-toolbar-background-mro {
  background-color: $purpleMRO1;
  color: white;
}
</style>
