import service from "./baseRequest.js";
//import { setToken } from "../../utils/jwt/tokenUtils.js";

export function getMaterials() {
  return service.get("materials");
}

export function getMaterialsWithConsume() {
  return service.get("materials/withConsume");
}
