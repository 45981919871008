<template>
  <q-btn style="display: none" @click="showNotify"></q-btn>
</template>

<script>
import { useQuasar } from "quasar";

export default {
  name: "MessageNotify",
  setup() {
    const $q = useQuasar();
    return {
      showNotify(message, color) {
        $q.notify({
          progress: true,
          message: message,
          color: color,
        });
      },
    };
  },
};
</script>
