<template>
  <q-table
    title="Tabela de Materiais"
    @row-click="onRowClick"
    :columns="columns"
    :rows="materialDataStates.userMaterialsData"
    virtual-scroll
    hide-pagination
    v-model:pagination="pagination"
    :rows-per-page-options="[0]"
    row-key="name"
    style="height: 600px"
  >
    <template v-slot:top-right>
      <q-toggle
        label="Consumo Histórico"
        v-model="materialDataStates.withConsumeFilter"
        icon="filter_alt"
        @update:model-value="getTableData"
      ></q-toggle>
    </template>
  </q-table>
</template>

<script>
import {
  getMaterials,
  getMaterialsWithConsume,
} from "../data/api/materialsRequests.js";
import { transformCamelCaseToSeparatedWords } from "../utils/string/stringManipulation.js";

export default {
  name: "MaterialsTable",
  setup() {},
  props: {},
  data: () => ({
    materialDataStates: {
      userMaterialsDataLoaded: false,
      userMaterialsData: [],
      withConsumeFilter: false,
    },

    columns: [
      {
        name: "materialCode",
        label: "CÓDIGO DE MATERIAL",
        align: "center",
        sortable: true,
        field: "materialCode",
      },
      {
        name: "shortDescription",
        label: "DESCRIÇÃO CURTA",
        align: "center",
        sortable: true,
        field: "shortDescription",
      },
      {
        name: "longDescription",
        label: "DESCRIÇÃO LONGA",
        align: "center",
        sortable: true,
        field: "longDescription",
      },
      {
        name: "umb",
        label: "UMB",
        align: "center",
        sortable: true,
        field: "umb",
      },
      {
        name: "family",
        label: "FAMÍLIA",
        align: "center",
        sortable: true,
        field: "family",
      },
      {
        name: "criticality",
        label: "CIRITICIDADE",
        align: "center",
        sortable: true,
        field: "criticality",
      },
      {
        name: "price",
        label: "PREÇO",
        align: "center",
        sortable: true,
        field: "price",
      },
      {
        name: "currencyIso",
        label: "ISO 4217",
        align: "center",
        sortable: true,
        field: "currencyIso",
      },
    ],

    pagination: 0,
  }),
  mounted() {
    this.getTableData();
  },
  computed: {
    collumnsSetup() {
      if (this.materialDataStates.userMaterialsDataLoaded) {
        let tableCollumns = [];
        Object.keys(this.materialDataStates.userMaterialsData[0]).forEach(
          (key) => {
            if (key != "genericField") {
              tableCollumns.push({
                name: key,
                label: transformCamelCaseToSeparatedWords(key),
                align: "left",
                sortable: true,
                field: key,
              });
            }
          }
        );
        return tableCollumns;
      } else {
        return [];
      }
    },
  },
  methods: {
    getTableData() {
      if (this.materialDataStates.withConsumeFilter) {
        let request = getMaterialsWithConsume();
        request.then((response) => {
          //console.log(response.data);
          this.materialDataStates.userMaterialsData = [...response.data];
          this.materialDataStates.userMaterialsDataLoaded = true;
        });
      } else {
        let request = getMaterials();
        request.then((response) => {
          //console.log(response.data);
          this.materialDataStates.userMaterialsData = [...response.data];
          this.materialDataStates.userMaterialsDataLoaded = true;
        });
      }
    },
    onRowClick(evt, row) {
      this.$emit("get-row", row);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.variables.scss";

.card-toolbar-background-mro {
  background-color: $purpleMRO1;
  color: white;
}

.district-dropdown-btn-color {
  background-color: $blueMRO2;
  color: white;
}
</style>
