<template>
  <q-dialog full-width>
    <q-card style="">
      <q-card-section class="">
        <div class="text-h4 q-mb-md">Aprovação</div>
        <div class="text-caption">
          Material: {{ dialogData ? dialogData["materialCode"] : "" }}
        </div>
        <div class="text-caption">Centro: {{ dialogData["district"] }}</div>
        <div class="text-caption">
          Departamento: {{ dialogData["department"] }}
        </div>
        <div class="text-caption">Usuário: {{ dialogData["user"] }}</div>
        <div class="text-caption">
          Data de Planejamento: {{ dialogData["planningDate"] }}
        </div>
      </q-card-section>
      <q-separator />
      <q-card-section>
        <div class="text-h5 q-mb-md">Histórico de Demanda</div>
        <DemandHistoryLineGraph
          :height="'200px'"
          :materialCode="dialogData['materialCode']"
          :district="dialogData['district']"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import DemandHistoryLineGraph from "../components/DemandHistoryLineGraph.vue";

export default {
  name: "SupervisorDialogCard",
  components: {
    DemandHistoryLineGraph,
  },
  props: {
    dialogData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({}),
  methods: {
    /*
    approveButtonClick() {
      this.approve ? (this.approve = false) : (this.approve = true);
      this.reject = false;
    },
    rejectButtonClick() {
      this.reject ? (this.reject = false) : (this.reject = true);
      this.approve = false;
    },
    onHide() {
      this.demandRequestApproval = null;
    },
    onShow() {
      this.material = this.dialogData ? this.dialogData.material : "";
      this.user = this.dialogData ? this.dialogData.usuario : "";
      this.district = this.dialogData ? this.dialogData.centro : "";
      this.department = this.dialogData ? this.dialogData.departamento : "";
    },
    plannedDemandMakeApproval(approval) {
      approval
        ? (this.approval.approvalType = 1)
        : (this.approval.approvalType = 0);
    },
    submitApproval() {
      //console.log(this.approval);
    },
    */
  },
};
</script>
